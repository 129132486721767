<template>
  <a-spin :tip="loadingTip" :spinning="loading">
    <a-form-model ref="qingshiform" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol"
      style="width: 80%; padding: 30px; margin: auto" :class="{ disabled: form.status == 2 }">
      <a-form-model-item label="期号" prop="number">
        <a-input v-model="form.number" v-show="false" />
        <div>
          <a-select v-model="number.year" style="width: 25%">
            <a-select-option v-for="item in years" :key="item" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
          第 <a-input v-model="number.qi" style="width: 10%"></a-input> 期
        </div>
      </a-form-model-item>
      <a-form-model-item label="标题" prop="title">
        <a-input v-model="form.title" />
      </a-form-model-item>
      <a-form-model-item label="时间" prop="time">
        <a-date-picker v-model="form.time" />
      </a-form-model-item>
      <a-form-model-item label="正文" style="pointer-events: all;">
        <a-button type="link" @click="showWps(form.print == 1, form.status == 2)">
          <span v-if="form.status == 0">填写正文</span>
          <span v-else-if="form.status == 3">编辑正文</span>
          <span v-else>查看正文</span>
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <template v-if="!detailMode">
      <div style="text-align: center">
        <template v-if="form.status == 0 || form.status == 3">
          <a-button type="primary" style="margin-right: 5px" @click="handleSubmit(0)">保存</a-button>
          <a-popconfirm title="提交后数据不可更改，是否确认？" @confirm="handleSubmit(1)">
            <a-button type="danger" style="margin-right: 5px">提交</a-button>
          </a-popconfirm>
        </template>
        <template v-else-if="form.status == 1 && form.processExecuteStatus">
          <a-button type="primary" style="margin-right: 5px" @click="showaudit()">审批</a-button>
        </template>
        <template v-else-if="form.status == 2 && allowFinish">
          <a-button type="primary" style="margin-right: 5px" @click="finishTask()">办理</a-button>
        </template>
        <a-button type="default" @click="cancelClick" class="cancelBtn">取消</a-button>
      </div>
    </template>
    <a-modal title="审批" v-model="auditModal.visible" width="40%" :footer="false" destroyOnClose :maskClosable="false">
      <audit :info="auditModal.info" @callback="auditCallBack" />
    </a-modal>
  </a-spin>
</template>

<script>
  import documentdispatchApi from "@/api/work/documentdispatch";
  import workflowApi from "@/api/workflow";
  import orgApi from "@/api/org";
  export default {
    props: {
      info: {
        type: Object,
        default: () => { },
      },
      detailMode: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        taskId: "",
        selectOrgs: [],
        selectOrgKeys: [],
        orgDates: "",
        currentOrgPath: "",
        currentOrgId: "",
        rootName: "",
        allowFinish: false,
        form: {
          status: 0,
          number: "",
          otherExtension: {},
          type: "",
        },
        number: {
          year: "",
          qi: "",
        },
        years: [],
        auditModal: {
          info: null,
          visible: false,
        },
        labelCol: { span: 4 },
        wrapperCol: { span: 16 },
        loading: false,
        loadingTip: "加载中...",
        rules: {
          title: [
            {
              required: true,
              message: "请输入标题",
              trigger: "blur",
            },
          ],
          number: [
            {
              required: true,
              message: "请编辑期号",
              trigger: "change",
            },
          ],
          time: [
            {
              required: true,
              message: "请选择时间",
              trigger: "change",
            },
          ],
        },
      };
    },
    mounted() {
      localStorage.removeItem("tsFileId");
      var d = new Date();
      var y = d.getFullYear();
      this.number.year = y;
      for (var i = -1; i < 3; ++i) {
        this.years.push(y - i);
      }
      this.getOrgTree();
      this.$nextTick(function () {
        if (this.info.id) {
          this.getData();
        } else {
          this.form.type = this.info.type;
          this.form.remoteDocument = this.$util.uuid();
        }
      });
    },
    components: {},
    methods: {
      showWps(readOnly, hand) {
        // hand 打印手写体
        if (readOnly) {
          // 只读模式
          localStorage.setItem("fileId", this.form.contentId);
          let hyData = {
            id: this.form.id,
            status: this.form.status,
            print: this.form.print,
            readOnly: true,
          };
          localStorage.setItem("hyData", JSON.stringify(hyData));
          const jump = this.$router.resolve({
            name: "wpsRead",
            query: {
              as: true,
              readOnly: true,
              printType: "custom_fawen",
            },
          });
          window.open(jump.href, "_blank");
          return;
        }

        let __number;
        let fileId = "";
        let fileType = "";
        let templateId = "";
        this.form.number = this.number.year + "-" + this.number.qi;
        this.$refs.qingshiform.validate((valid) => {
          if (valid) {
            __number = this.form.number;
            fileId = localStorage.getItem("FileId");
            fileType = "ts";
            templateId = "zhibugongzuodongtai";
            let data = {
              id: this.form.id,
              status: this.form.status,
              print: this.form.print,
              title: this.form.title,
              headTitle: this.form.headTitle,
              date: this.form.time ? this.form.time.format("YYYY年MM月DD日") : "",
            };
            if (hand) {
              let fin = this.form.auditRecords.find((a) => a.taskKey == "院长");
              if (fin) {
                let signPic = fin.auditUserSignPicture;
                signPic =
                  "http://localhost:9000/" +
                  signPic.substring(signPic.indexOf("uas"));
                data.readOnly = true;
                data.hand = true;
                data.signPic = signPic;
              }
            }
            localStorage.setItem("innerData", JSON.stringify(data));

            let openType = "create";
            if (fileId && fileId != "") {
              openType = "edit";
              templateId = fileId;
            }
            if (this.form.contentId && this.form.contentId != "") {
              openType = "edit";
              templateId = this.form.contentId;
            }

            if (this.form.status != 0 && this.form.status != 3) {
              openType = "readOnly";
            }

            const jump = this.$router.resolve({
              name: "wpsedit",
              query: {
                //要传的参数
                templateId: templateId,
                number: __number,
                fileType: fileType,
                openType: openType,
                printType: "custom_fawen",
              },
            });
            window.open(jump.href, "_blank");
          }
        });
      },
      showaudit() {
        this.auditModal.info = this.form;
        this.auditModal.visible = true;
      },
      async auditCallBack(data) {
        this.auditModal.visible = false;
        this.$emit("callback", data != null);
      },
      getData() {
        this.loading = true;
        documentdispatchApi.getData(this.info.id).then((res) => {
          this.loading = false;
          if (res.errorCode == this.$commons.RespCode.success) {
            res.data.time = this.$moment(res.data.time, "YYYY-MM-DD");
            if (res.data.otherExtension) {
              res.data.otherExtension = JSON.parse(res.data.otherExtension);
            }
            this.form = res.data;
            if (
              this.info &&
              this.info.taskId &&
              this.info.taskId != this.form.taskId
            ) {
              this.form.taskId = this.info.taskId;
            }
            this.number.year = this.form.number.split("-")[0];
            this.number.qi = this.form.number.split("-")[1];
            if (this.form.remoteDocument == null) {
              this.form.remoteDocument = this.$util.uuid();
            }
            if (this.form.selectOrgs != null) {
              let _ss = JSON.parse(this.form.selectOrgs);
              this.selectOrgKeys = _ss.selectOrgKeys;
              this.selectOrgs = _ss.selectOrgs;
            }
            this.currentOrgPath = this.rootName + "/" + this.form.creatorOrgName;
            this.currentOrgId = this.form.creatorOrgId;
            this.getTask(res.data.processId);
          }
        });
      },
      async finishTask() {
        if (this.taskId == "" || this.taskId == null) {
          this.$message.error("已办理");
          return;
        }
        this.worktaskloading = true;
        var res = await workflowApi.finishTask(this.taskId);
        this.worktaskloading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.$message.success("操作成功");
          this.getData();
        } else {
          this.$message.error(res.errorMsg);
        }
      },
      getTask(id) {
        this.loading = true;
        this.taskId = "";
        this.allowFinish = false;
        workflowApi.getTask(id).then((res) => {
          this.loading = false;
          if (res.errorCode == this.$commons.RespCode.success) {
            if (res.data != null && res.data != "" && res.data.length > 0) {
              this.taskId = res.data[0].id;
              this.allowFinish = true;
              this.$forceUpdate();
            }
          }
        });
      },
      async getOrgTree() {
        let res = await orgApi.getTree(true, true);
        if (res.errorCode == this.$commons.RespCode.success) {
          let currentOrgId = res.data.path[res.data.path.length - 1];
          this.rootName = res.data.tree[0].path;
          this.orgDates = res.data.tree[0].children.filter(
            (n) => n.id != currentOrgId
          );
          let _d = res.data.tree[0].children.filter(
            (n) => n.id == currentOrgId
          )[0];
          if (_d) {
            this.currentOrgPath = _d.path;
            this.currentOrgId = _d.id;
          }
        }
      },
      handleSubmit(status) {
        if (localStorage.getItem("tsFileId") != null) {
          this.form.contentId = localStorage.getItem("tsFileId");
        }

        if (this.form.status == 3 && localStorage.getItem("tsFileId") == null) {
          this.$message.error("请先重新编辑正文内容");
          return;
        }

        if (this.form.contentId == null) {
          this.$message.error("请先填写正文内容");
          return;
        }
        let extensionSettingData = {};
        let leadOrgs = "";
        let mainNode = "";
        if (this.selectOrgs && this.selectOrgs.length > 0) {
          this.selectOrgKeys.forEach((item) => {
            leadOrgs = leadOrgs + "/?{'leadOrgs':" + item + "}|";
          });
          this.selectOrgs.forEach((item) => {
            mainNode =
              mainNode + "/" + this.rootName + "/" + item + "/{'mainNode':true}|";
          });
        }
        leadOrgs = leadOrgs + "/?{'leadOrgs':" + this.currentOrgId + "}";
        let currOrgPath =
          this.currentOrgPath.indexOf(this.rootName) == -1
            ? this.rootName + "/" + this.currentOrgPath
            : this.currentOrgPath;
        mainNode = mainNode + "/" + currOrgPath + "/{'mainNode':true}";
        extensionSettingData["key_部室负责人"] = {
          targetOrg: mainNode,
          count: this.selectOrgs.length + 1,
        };
        let leadOrgs_data = {};
        leadOrgs_data.orgPattern = leadOrgs;
        leadOrgs_data.user = localStorage.getItem(this.$commons.User.userName);
        orgApi.getMatchUsers(leadOrgs_data).then((res) => {
          extensionSettingData["key_分管院领导"] = {
            targetUsers: res.toString(),
            count: res.length,
          };
          this.form.extensionSetting = JSON.stringify(extensionSettingData);
          let selectOrg = {
            selectOrgs: this.selectOrgs,
            selectOrgKeys: this.selectOrgKeys,
          };
          this.form.selectOrgs = JSON.stringify(selectOrg);
          this.form.number = this.number.year + "-" + this.number.qi;
          this.$refs.qingshiform.validate((valid) => {
            if (valid) {
              let formData = { ...this.form, status };

              if (this.form.otherExtension) {
                formData.otherExtension = JSON.stringify(
                  this.form.otherExtension
                );
              }
              formData.time = this.form.time.format("YYYY-MM-DD");
              if (this.info.id) {
                formData.id = this.info.id;
              }
              formData.orgType = this.currentOrgPath;
              delete formData.auditRecords;
              this.loading = true;
              this.loadingTip = "保存中...";
              //添加
              documentdispatchApi
                .addOrUpdate(formData)
                .then((res) => {
                  if (res.errorCode == this.$commons.RespCode.success) {
                    this.$message.success(res.errorMsg);
                    this.$emit("callback", true);
                  } else {
                    this.$message.error(res.errorMsg);
                  }
                  this.loading = false;
                })
                .catch(() => {
                  this.loading = false;
                });
            } else {
              return false;
            }
          });
        });
      },
      cancelClick() {
        this.$emit("callback", false);
      },
    },
  };
</script>
<style>
  .edui-default .edui-toolbar {
    line-height: 20px !important;
  }
</style>