<template>
  <tbody>
    <tr>
      <td rowspan="3" style="border-bottom: none">院长签发</td>
      <td rowspan="3" colspan="2" style="border-bottom: none; width: 300px">
        <auditshow :infoData="form" taskKey="省教科院发文卡-院长签发" @callback="auditshowBack" />
      </td>
      <td>办公室意见</td>
      <td colspan="2">
        <auditshow :infoData="form" taskKey="省教科院发文卡-办公室意见" @callback="auditshowBack" />
      </td>
    </tr>
    <tr>
      <td><span style="color: red">*</span>职能部室</td>
      <td colspan="2">
        <a-select v-model="selectOrgs" mode="multiple" v-if="form.status == 0 || form.status == 3"
          @change="selectOrgschange">
          <a-select-option v-for="item in orgDates" :key="item.id" :value="item.path">
            {{ item.name }}
          </a-select-option>
        </a-select>
        <auditshow v-if="form.status == 1 || form.status == 2" :infoData="form" taskKey="省教科院发文卡-职能部室"
          @callback="auditshowBack" />
      </td>
    </tr>
    <tr>
      <td style="border-bottom: none">部室意见</td>
      <td colspan="2" style="border-bottom: none">
        <auditshow :infoData="form" taskKey="省教科院发文卡-部室意见" @callback="auditshowBack" />
      </td>
    </tr>
    <tr>
      <td rowspan="3" style="border-bottom: none">主管领导意见</td>
      <td rowspan="3" colspan="2" style="border-bottom: none">
        <auditshow :infoData="form" taskKey="省教科院发文卡-主管领导意见" @callback="auditshowBack" />
      </td>
      <td>会签意见</td>
      <td colspan="2">
        <MultiSearchUser v-if="form.status == 0 || form.status == 3" :checkUsers="selUser"
          @callback="selectUsersCallback" />
        <auditshow v-if="form.status == 1 || form.status == 2" :infoData="form" taskKey="省教科院发文卡-会签意见"
          @callback="auditshowBack" />
      </td>
    </tr>
    <tr>
      <td><span style="color: red">*</span>核稿</td>
      <td colspan="2">
        <a-input-search class="select_search" placeholder="请选择核稿人" readOnly v-model="form.hegaoName" @click="onSearch"
          @search="onSearch" v-if="form.status == 0 || form.status == 3">
          <a-button type="primary" slot="enterButton" icon="plus" title="添加" />
        </a-input-search>
        <auditshow v-if="form.status == 1 || form.status == 2" :infoData="form" taskKey="省教科院发文卡-核稿"
          @callback="auditshowBack" />
      </td>
    </tr>
    <tr>
      <td style="border-bottom: none"><span style="color: red">*</span>拟稿</td>
      <td colspan="2" style="border-bottom: none; text-align: left; pointer-events: all;">
        <a-button v-if="form.status == 0 || form.status == 3" type="link" @click="showWps">{{ form.status == 0 ? "填写正文"
          : "编辑正文" }}</a-button>
        <a-button v-else type="link" @click="showWps">查看正文</a-button>
        <a-popconfirm title="提交后数据不可更改，是否确认？" @confirm="handleSubmit()" v-if="form.status == 0 || form.status == 3">
          <a-button type="link" style="margin-right: 10px">提交</a-button>
        </a-popconfirm>
        <span v-if="form.status > 0"> {{ form.otherExtension.remark }}</span>
      </td>
    </tr>
    <a-modal title="选择用户" v-model="selectuservisible" width="70%" :footer="false" destroyOnClose :maskClosable="false">
      <selectuser @callback="selectUserCallback" />
    </a-modal>
  </tbody>
</template>
<script>
  import orgApi from "@/api/org";
  import selectuser from "../components/searchuser.vue";
  import MultiSearchUser from "../components/MultiSearchUser.vue";
  export default {
    name: "yanjiuyuan",
    props: {
      formData: {
        type: Object,
      },
    },
    data() {
      return {
        form: this.formData,
        orgs: null,
        selUser: [],
        selectuservisible: false,
        orgDates: [],
        selectOrgs: [],
        selectOrgKeys: [],
      };
    },
    watch: {
      formData(v) {
        this.form = v;
      },
    },
    mounted() {
      if (
        this.formData.extensionSetting &&
        this.formData.extensionSetting["node_省教科院发文卡-职能部室"]
      ) {
        let fullOrgs =
          this.formData.extensionSetting[
            "node_省教科院发文卡-职能部室"
          ].targetOrg.split("|");
        fullOrgs.forEach((item) => {
          let orgPath = item.split("/");
          this.selectOrgs.push(orgPath[orgPath.length - 2]);
        });
      }
      if (this.formData.userInfo) {
        this.selUser = JSON.parse(this.formData.userInfo);
      }
      this.getOrgTree();
    },
    components: {
      selectuser,
      MultiSearchUser,
    },
    methods: {
      auditshowBack() {
        this.$emit("callback");
      },
      selectOrgschange(value, option) {
        this.selectOrgKeys = [];
        for (let i = 0; i < option.length; i++) {
          this.selectOrgKeys.push(option[i].key);
        }
      },
      handleSubmit() {
        if (this.selectOrgs == null || this.selectOrgs.length == 0) {
          this.$message.error("请选职能部室");
          return;
        }
        if (
          this.form.status == 3 &&
          localStorage.getItem("publicFileId") == null
        ) {
          this.$message.error("请先重新编辑正文内容");
          return;
        }
        this.form.contentId = localStorage.getItem("publicFileId");
        if (this.form.contentId == null) {
          this.$message.error("请先填写正文内容");
          return;
        }
        let _d = "";
        this.selectOrgs.forEach((item) => {
          _d = _d + "/" + item + "/主任|";
        });
        _d = _d.substring(0, _d.length - 1);
        let backData = {
          extensionSettingData: {},
          userInfo: [],
        };
        let extensionSettingData = {};
        extensionSettingData["node_省教科院发文卡-职能部室"] = {
          targetOrg: _d,
          count: this.selectOrgs.length,
        };
        let selectOrgs = "";
        for (let i = 0; i < this.selectOrgKeys.length; i++) {
          selectOrgs =
            selectOrgs + "/?{'leadOrgs':" + this.selectOrgKeys[i] + "}|";
        }
        selectOrgs = selectOrgs.substring(0, selectOrgs.length - 1);

        let data = {
          orgPattern: selectOrgs,
          user: localStorage.getItem(this.$commons.User.userName),
        };
        orgApi.getMatchUsers(data).then((res) => {
          extensionSettingData["node_省教科院发文卡-主管领导意见"] = {
            targetUsers: res.toString(),
            count: res.length,
          };
          // if (this.form.huiqian == null || this.form.huiqian == "") {
          //   this.$message.error("请选择会签人");
          //   return;
          // }
          if (this.form.hegao == null || this.form.hegao == "") {
            this.$message.error("请选择核稿人");
            return;
          }

          extensionSettingData["node_省教科院发文卡-核稿"] = {
            targetUsers: this.form.hegao,
            count: 1,
          };
          if (this.form.huiqian && this.form.huiqian.length > 0) {
            extensionSettingData["node_省教科院发文卡-会签意见"] = {
              targetUsers: this.form.huiqian,
              count: this.form.huiqian.split(",").length,
            };
          }
          backData.extensionSettingData = extensionSettingData;
          backData.userInfo = JSON.stringify(this.selUser);
          this.$emit("callback", backData);
        });
      },
      getOrgTree() {
        orgApi.getTree(true, true).then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            this.orgDates = res.data.tree[0].children;
            if (this.selectOrgs.length > 0) {
              this.orgDates.forEach((item) => {
                if (this.selectOrgs.indexOf(item.name) > -1) {
                  this.selectOrgKeys.push(item.id);
                }
              });
            }
          }
        });
      },
      onSearch() {
        this.selectuservisible = true;
      },
      selectUserCallback(user) {
        this.form.hegao = user.userName;
        this.form.hegaoName = user.realName;
        this.selectuservisible = false;
      },
      selectUsersCallback(users) {
        this.selUser = [...users];
        this.form.huiqian = users.map((u) => u.userName).join(",");
        this.form.huiqianName = users.map((u) => u.realName).join(",");
      },
      showWps() {
        let number1 = this.form.number;
        this.$emit("callback", null, true);
        if (this.form.title == "") {
          this.$message.error("请输入标题");
          return;
        }
        if (this.form.dispatchOrg == "") {
          this.$message.error("请输入主送单位");
          return;
        }
        let _number = this.form.number;
        let reg = /(.+)-(.+)/;
        let reg2 = /(.+)-(.+)-(.+)/;
        if (!reg.test(_number)) {
          this.$message.error("请正确输入发文编号");
          return;
        }
        if (!reg2.test(_number) && this.form.status == 2) {
          this.$message.error("请完善发文编号");
          return;
        }
        if (this.form.time == undefined) {
          this.$message.error("请选择发文日期");
          return;
        }
        let templateId = "fawenkaJiaoKeYuanBan";
        if (_number.indexOf("冀教院函") != -1) {
          templateId = "fawenkaJiaoKeYuanHan";
        }
        let fileId = localStorage.getItem("publicFileId");
        let openType = "create";
        if (fileId != null && fileId != "") {
          openType = "edit";
          templateId = fileId;
        }
        if (this.form.contentId != null && this.form.contentId != "") {
          openType = "edit";
          templateId = this.form.contentId;
        }

        if (
          this.form.status == 3 &&
          this.form.contentId == localStorage.getItem("publicFileId")
        ) {
          openType = "create";
          templateId = "fawenkaJiaoKeYuanBan";
          if (_number.indexOf("冀教院函") != -1) {
            templateId = "fawenkaJiaoKeYuanHan";
          }
          localStorage.removeItem("publicFileId");
        }

        if (this.form.status != 0 && this.form.status != 3) {
          openType = "readOnly";
          localStorage.removeItem("publicFileId");
        }
        let innerData = {
          id: this.form.id,
          status: this.form.status,
          print: this.form.print,
          name: _number.split("-")[0],
          year: _number.split("-")[1],
          number: _number.split("-")[2],
          title: this.form.title,
          headTitle: this.form.dispatchOrg,
          date: this.form.time.format("YYYY年MM月DD日"),
          updateNumber: this.form.status == 2,
        };
        localStorage.setItem("innerData", JSON.stringify(innerData));
        const jump = this.$router.resolve({
          name: "wpsedit",
          query: {
            //要传的参数
            templateId: templateId,
            fileType: "public",
            openType: openType,
            fileName: "省教科院发文卡_" + _number.replaceAll("-", "_"),
            printType: "custom_fawen",
          },
        });

        window.open(jump.href, "_blank");
      },
    },
  };
</script>