<template>
  <a-spin :tip="loadingTip" :spinning="loading">
    <a-form-model ref="qingshiform" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol"
      style="width: 80%; padding: 30px; margin: auto" :class="{ disabled: form.status == 2 }">
      <a-form-model-item label="标题" prop="title">
        <a-input v-model="form.title" />
      </a-form-model-item>
      <a-form-model-item label="抬头" prop="headTitle">
        <a-input v-model="form.headTitle" />
      </a-form-model-item>
      <a-form-model-item label="落款" prop="signOff" v-if="
        form.type == '请示' ||
        form.type == '报告' ||
        form.type == '党支部请示'
      ">
        <a-input v-model="form.signOff" />
      </a-form-model-item>
      <a-form-model-item label="时间" prop="time">
        <a-date-picker v-model="form.time" />
      </a-form-model-item>
      <a-form-model-item label="正文" style="pointer-events: all;">
        <a-button type="link" @click="
          showWps(
            form.print == 1,
            form.type.indexOf('通知') != -1 && form.status == 2
          )
          ">
          <span v-if="form.status == 0">填写正文</span>
          <span v-else-if="form.status == 3">编辑正文</span>
          <span v-else>查看正文</span>
        </a-button>
      </a-form-model-item>
      <a-form-model-item label="类型" v-if="form.type == '请示'">
        <a-radio-group :options="typeOptions" v-model="currentOrgPath" />
      </a-form-model-item>
      <a-form-model-item label="协同部室" v-if="form.type.indexOf('通知') == -1 && form.type != '党支部请示'">
        <a-select v-model="selectOrgs" mode="multiple" style="width: 450px" v-if="form.status == 0 || form.status == 3"
          @change="selectOrgschange">
          <a-select-option v-for="item in orgDates" :key="item.id" :value="item.name">
            {{ item.name }}
          </a-select-option>
        </a-select>
        <div v-else>{{ selectOrgs.toString() }}</div>
      </a-form-model-item>
      <a-form-model-item label="附件">
        <a-upload :action="uploadFileUrl" :fileList="fileList" @change="handleFileChange" @preview="showWpsRead"
          :remove="upLoadRemove" style="pointer-events: all;">
          <div v-if="this.form.status == 0 || form.status == 3">
            <a-button><a-icon type="upload" />上传文件</a-button>
          </div>
        </a-upload>
      </a-form-model-item>
    </a-form-model>
    <template v-if="!detailMode">
      <div style="text-align: center">
        <template v-if="form.status == 0 || form.status == 3">
          <a-button type="primary" style="margin-right: 5px" @click="handleSubmit(0)">保存</a-button>
          <a-popconfirm title="提交后数据不可更改，是否确认？" @confirm="handleSubmit(1)">
            <a-button type="danger" style="margin-right: 5px">提交</a-button>
          </a-popconfirm>
        </template>
        <template v-else-if="form.status == 1 && form.processExecuteStatus">
          <a-button type="primary" style="margin-right: 5px" @click="showaudit()">审批</a-button>
        </template>
        <template v-else-if="form.status == 2 && allowFinish">
          <a-button type="primary" style="margin-right: 5px" @click="finishTask()">办理</a-button>
        </template>
        <a-button type="default" @click="cancelClick" class="cancelBtn">取消</a-button>
      </div>
    </template>
    <a-modal title="审批" v-model="auditModal.visible" width="40%" :footer="false" destroyOnClose :maskClosable="false">
      <audit :info="auditModal.info" @callback="auditCallBack" />
    </a-modal>
  </a-spin>
</template>

<script>
  import documentdispatchApi from "@/api/work/documentdispatch";
  import request from "@/request";
  import workflowApi from "@/api/workflow";
  import orgApi from "@/api/org";
  export default {
    props: {
      info: {
        type: Object,
        default: () => { },
      },
      detailMode: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        taskId: "",
        selectOrgs: [],
        selectOrgKeys: [],
        orgDates: "",
        currentOrgPath: "",
        currentOrgId: "",
        rootName: "",
        allowFinish: false,
        form: {
          status: 0,
          otherExtension: {},
          type: "",
        },
        auditModal: {
          info: null,
          visible: false,
        },
        uploadFileUrl: "",
        labelCol: { span: 4 },
        wrapperCol: { span: 16 },
        loading: false,
        loadingTip: "加载中...",
        fileList: [],
        rules: {
          title: [
            {
              required: true,
              message: "请输入标题",
              trigger: "blur",
            },
          ],
          headTitle: [
            {
              required: true,
              message: "请输入抬头",
              trigger: "blur",
            },
          ],
          dispatchOrg: [
            {
              required: true,
              message: "请输入单位",
              trigger: "blur",
            },
          ],
          time: [
            {
              required: true,
              message: "请选择时间",
              trigger: "change",
            },
          ],
          signOff: [
            {
              required: true,
              message: "请输入落款",
              trigger: "blur",
            },
          ],
        },
        typeOptions: [
          { label: "常规", value: "" },
          {
            label: "教研",
            value: "河北省教育科学研究院/课程管理部（课程教材研究室）",
          },
          { label: "科研", value: "河北省教育科学研究院/科研管理部" },
        ],
      };
    },
    mounted() {
      localStorage.removeItem("tsFileId");
      localStorage.removeItem("bbFileId");
      this.uploadFileUrl = request.getUploadUrl("document");
      this.getOrgTree();
      this.$nextTick(function () {
        if (this.info.id) {
          this.getData();
        } else {
          this.form.type = this.info.type;
          this.form.remoteDocument = this.$util.uuid();
        }
      });
    },
    components: {},
    methods: {
      upLoadRemove() {
        return this.form.status == 0 || this.form.status == 3;
      },
      showWpsRead(file) {
        let type = file.name.substring(file.name.lastIndexOf(".") + 1);
        if (
          type.toLowerCase().indexOf("doc") <= -1 &&
          type.toLowerCase().indexOf("xls") <= -1 &&
          type.toLowerCase().indexOf("ppt") <= -1
        ) {
          window.open(file.response.url, "_blank");
        } else {
          let fileId =
            "file" +
            file.response.url
              .substring(
                file.response.url.lastIndexOf("/") + 1,
                file.response.url.lastIndexOf(".")
              )
              .replaceAll("-", "");
          let url = file.response.url.substring(
            file.response.url.indexOf("oabucket")
          );
          let type = file.response.url.substring(
            file.response.url.lastIndexOf(".") + 1
          );
          localStorage.setItem("fileRrl", url);
          localStorage.setItem("fileName", file.name);
          localStorage.setItem("fileId", fileId);
          localStorage.setItem("fileTpye", type);
          const jump = this.$router.resolve({
            name: "wpsRead",
            query: {
              //要传的参数
              fileName: file.name,
            },
          });

          window.open(jump.href, "_blank");
        }
      },
      showWps(readOnly, hand) {
        // hand 打印手写体，通知使用
        if (readOnly) {
          // 只读模式
          localStorage.setItem("fileId", this.form.contentId);
          let hyData = {
            id: this.form.id,
            status: this.form.status,
            print: this.form.print,
            readOnly: true,
          };
          if (hand) {
            let fin = this.form.auditRecords.find((a) => a.taskKey == "院长");
            if (fin) {
              let signPic = fin.auditUserSignPicture;
              signPic =
                "http://localhost:9000/" +
                signPic.substring(signPic.indexOf("uas"));
              hyData.hand = true;
              hyData.signPic = signPic;
            }
          }
          localStorage.setItem("hyData", JSON.stringify(hyData));
          const jump = this.$router.resolve({
            name: "wpsRead",
            query: {
              as: true,
              readOnly: true,
              printType: "custom_fawen",
            },
          });
          window.open(jump.href, "_blank");
          return;
        }

        let __number;
        let fileId = "";
        let fileType = "";
        let templateId = "";
        this.$refs.qingshiform.validate((valid) => {
          if (valid) {
            if (
              this.form.type == "报告" ||
              this.form.type == "请示" ||
              this.form.type == "党支部请示"
            ) {
              __number = "情况报告";
              fileId = localStorage.getItem("bbFileId");
              fileType = "bb";
              templateId = "A0020001";
              if (this.form.type == "党支部请示") {
                __number = "党支部请示";
                templateId = "dangzhibuqingshi";
              }
              let data = {
                id: this.form.id,
                status: this.form.status,
                print: this.form.print,
                title: this.form.title,
                headTitle: this.form.headTitle,
                signOff: this.form.signOff,
                date: this.form.time
                  ? this.form.time.format("YYYY年MM月DD日")
                  : "",
              };
              localStorage.setItem("innerData", JSON.stringify(data));
            } else {
              //通知
              __number = this.form.type;
              fileId = localStorage.getItem("tsFileId");
              fileType = "ts";
              if (this.info.type == "省教科院通知") {
                templateId = "A0010002";
              } else if (this.info.type == "省规划办通知") {
                templateId = "A0010004";
              } else if (this.info.type == "省教育学会通知") {
                templateId = "A0010003";
              }
              let data = {
                id: this.form.id,
                status: this.form.status,
                print: this.form.print,
                title: this.form.title,
                headTitle: this.form.headTitle,
                date: this.form.time
                  ? this.form.time.format("YYYY年MM月DD日")
                  : "",
              };
              if (hand) {
                let fin = this.form.auditRecords.find((a) => a.taskKey == "院长");
                if (fin) {
                  let signPic = fin.auditUserSignPicture;
                  signPic =
                    "http://localhost:9000/" +
                    signPic.substring(signPic.indexOf("uas"));
                  data.readOnly = true;
                  data.hand = true;
                  data.signPic = signPic;
                }
              }
              localStorage.setItem("innerData", JSON.stringify(data));
            }

            let openType = "create";
            if (fileId && fileId != "") {
              openType = "edit";
              templateId = fileId;
            }
            if (this.form.contentId && this.form.contentId != "") {
              openType = "edit";
              templateId = this.form.contentId;
            }

            if (this.form.status != 0 && this.form.status != 3) {
              openType = "readOnly";
            }

            const jump = this.$router.resolve({
              name: "wpsedit",
              query: {
                //要传的参数
                templateId: templateId,
                number: __number,
                fileType: fileType,
                openType: openType,
                printType: "custom_fawen",
              },
            });
            window.open(jump.href, "_blank");
          }
        });
      },
      showaudit() {
        this.auditModal.info = this.form;
        this.auditModal.visible = true;
      },
      async auditCallBack(data) {
        this.auditModal.visible = false;
        this.$emit("callback", data != null);
      },
      handleFileChange(fileInfo) {
        this.fileList = fileInfo.fileList;
        if (fileInfo.file.status == "error") {
          this.$message.error("上传失败");
        }
      },
      getData() {
        this.loading = true;
        documentdispatchApi.getData(this.info.id).then((res) => {
          this.loading = false;
          if (res.errorCode == this.$commons.RespCode.success) {
            res.data.time = this.$moment(res.data.time, "YYYY-MM-DD");
            if (res.data.otherExtension) {
              res.data.otherExtension = JSON.parse(res.data.otherExtension);
            }
            if (res.data.attachment) {
              var d = JSON.parse(res.data.attachment);
              var files = [];
              for (var i = 1; i <= d.length; ++i) {
                var a = d[i - 1];
                files.push({
                  uid: i,
                  name: a.title,
                  status: "done",
                  response: a,
                  url: a.url,
                });
              }
              this.fileList = files;
            }
            this.form = res.data;
            if (
              this.info &&
              this.info.taskId &&
              this.info.taskId != this.form.taskId
            ) {
              this.form.taskId = this.info.taskId;
            }
            if (this.form.remoteDocument == null) {
              this.form.remoteDocument = this.$util.uuid();
            }
            if (this.form.selectOrgs != null) {
              let _ss = JSON.parse(this.form.selectOrgs);
              this.selectOrgKeys = _ss.selectOrgKeys;
              this.selectOrgs = _ss.selectOrgs;
            }
            // 请示中，currentOrgPath为请示的类型，按照请示类型赋值；其他按照创建人部门赋值
            this.currentOrgPath = this.form.orgType
              ? this.form.orgType
              : this.rootName + "/" + this.form.creatorOrgName;
            this.currentOrgId = this.form.creatorOrgId;
            // 请示中请示类型的选择项，常规的value值为当前创建人的部门
            this.typeOptions[0].value =
              this.rootName + "/" + this.form.creatorOrgName;
            this.getTask(res.data.processId);
          }
        });
      },
      async finishTask() {
        if (this.taskId == "" || this.taskId == null) {
          this.$message.error("已办理");
          return;
        }
        var res = await workflowApi.finishTask(this.taskId);
        if (res.errorCode === this.$commons.RespCode.success) {
          this.$message.success("操作成功");
          this.getData();
        } else {
          this.$message.error(res.errorMsg);
        }
      },
      getTask(id) {
        this.loading = true;
        this.taskId = "";
        this.allowFinish = false;
        workflowApi.getTask(id).then((res) => {
          this.loading = false;
          if (res.errorCode == this.$commons.RespCode.success) {
            if (res.data != null && res.data != "" && res.data.length > 0) {
              this.taskId = res.data[0].id;
              this.allowFinish = true;
              this.$forceUpdate();
            }
          }
        });
      },
      selectOrgschange(value, option) {
        this.selectOrgKeys = [];
        for (let i = 0; i < option.length; i++) {
          this.selectOrgKeys.push(option[i].key);
        }
      },
      async getOrgTree() {
        let res = await orgApi.getTree(true, true);
        if (res.errorCode == this.$commons.RespCode.success) {
          let currentOrgId = res.data.path[res.data.path.length - 1];
          this.rootName = res.data.tree[0].path;
          this.orgDates = res.data.tree[0].children.filter(
            (n) => n.id != currentOrgId
          );
          let _d = res.data.tree[0].children.filter(
            (n) => n.id == currentOrgId
          )[0];
          if (_d) {
            this.currentOrgPath = _d.path;
            this.currentOrgId = _d.id;
            this.typeOptions[0].value = _d.path;
          }
        }
      },
      handleSubmit(status) {
        if (
          this.form.type.indexOf("通知") != -1 &&
          localStorage.getItem("tsFileId") != null
        ) {
          this.form.contentId = localStorage.getItem("tsFileId");
        }
        if (
          (this.form.type == "请示" ||
            this.form.type == "报告" ||
            this.form.type == "党支部请示") &&
          localStorage.getItem("bbFileId") != null
        ) {
          this.form.contentId = localStorage.getItem("bbFileId");
        }
        if (
          this.form.status == 3 &&
          this.form.type.indexOf("通知") != -1 &&
          localStorage.getItem("tsFileId") == null
        ) {
          this.$message.error("请先重新编辑正文内容");
          return;
        }
        if (
          this.form.status == 3 &&
          localStorage.getItem("bbFileId") == null &&
          (this.form.type == "请示" ||
            this.form.type == "报告" ||
            this.form.type == "党支部请示")
        ) {
          this.$message.error("请先重新编辑正文内容");
          return;
        }

        if (this.form.contentId == null) {
          this.$message.error("请先填写正文内容");
          return;
        }
        let extensionSettingData = {};
        let leadOrgs = "";
        let mainNode = "";
        // 协同部室
        if (this.selectOrgs && this.selectOrgs.length > 0) {
          // 审核流程中的部室负责人节点数据
          this.selectOrgs.forEach((item) => {
            mainNode =
              mainNode + "/" + this.rootName + "/" + item + "/{'mainNode':true}|";
          });
          // 部室的分管领导节点数据
          this.selectOrgKeys.forEach((item) => {
            leadOrgs = leadOrgs + "/?{'leadOrgs':" + item + "}|";
          });
        }
        // 当前部门(请示中为选择的请示类型的部门)负责人节点数据
        mainNode = mainNode + "/" + this.currentOrgPath + "/{'mainNode':true}";
        let selOrgObj = this.orgDates.find(
          (item) => item.path == this.currentOrgPath
        );
        // 当前部门(请示中为选择的请示类型的部门)分管领导节点数据
        let currOrgId = selOrgObj ? selOrgObj.id : this.currentOrgId;
        leadOrgs = leadOrgs + "/?{'leadOrgs':" + currOrgId + "}";
        extensionSettingData["key_部室负责人"] = {
          targetOrg: mainNode,
          count: this.selectOrgs.length + 1,
        };
        let leadOrgs_data = {
          orgPattern: leadOrgs,
          user: localStorage.getItem(this.$commons.User.userName),
        };
        orgApi.getMatchUsers(leadOrgs_data).then((res) => {
          extensionSettingData["key_分管院领导"] = {
            targetUsers: res.toString(),
            count: res.length,
          };
          this.form.extensionSetting = JSON.stringify(extensionSettingData);
          let selectOrg = {
            selectOrgs: this.selectOrgs,
            selectOrgKeys: this.selectOrgKeys,
          };
          this.form.selectOrgs = JSON.stringify(selectOrg);
          this.$refs.qingshiform.validate((valid) => {
            if (valid) {
              let formData = { ...this.form, status };
              if (this.form.otherExtension) {
                formData.otherExtension = JSON.stringify(
                  this.form.otherExtension
                );
              }
              formData.time = this.form.time.format("YYYY-MM-DD");
              if (this.fileList.length > 0) {
                var file = [];
                this.fileList.forEach((f) => {
                  file.push({
                    title: f.response.title,
                    url: f.response.url,
                  });
                });
                formData.attachment = JSON.stringify(file);
              }
              if (this.info.id) {
                formData.id = this.info.id;
              }
              formData.orgType = this.currentOrgPath;
              delete formData.auditRecords;
              this.loading = true;
              this.loadingTip = "保存中...";
              //添加
              documentdispatchApi
                .addOrUpdate(formData)
                .then((res) => {
                  if (res.errorCode == this.$commons.RespCode.success) {
                    this.$message.success(res.errorMsg);
                    this.$emit("callback", true);
                  } else {
                    this.$message.error(res.errorMsg);
                  }
                  this.loading = false;
                })
                .catch(() => {
                  this.loading = false;
                });
            } else {
              return false;
            }
          });
        });
      },
      cancelClick() {
        this.$emit("callback", false);
      },
    },
  };
</script>
<style>
  .edui-default .edui-toolbar {
    line-height: 20px !important;
  }
</style>