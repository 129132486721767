<template>
  <tbody>
    <tr>
      <td rowspan="2" style="border-bottom: none">主任签发</td>
      <td rowspan="2" colspan="2" style="border-bottom: none">
        <auditshow :infoData="form" taskKey="省规划办发文卡-主任签发" @callback="auditshowBack" />
      </td>
      <td>科研管理部意见</td>
      <td colspan="2">
        <auditshow :infoData="form" taskKey="省规划办发文卡-科研管理部意见" @callback="auditshowBack" />
      </td>
    </tr>
    <tr>
      <td>规划办秘书组意见</td>
      <td colspan="2">
        <auditshow :infoData="form" taskKey="省规划办发文卡-规划办秘书处意见" @callback="auditshowBack" />
      </td>
    </tr>

    <tr>
      <td rowspan="3" style="border-bottom: none">副主任意见</td>
      <td rowspan="3" colspan="2" style="border-bottom: none">
        <auditshow :infoData="form" taskKey="省规划办发文卡-副主任意见" @callback="auditshowBack" />
      </td>
      <td>会签意见</td>
      <td colspan="2">
        <MultiSearchUser v-if="form.status == 0 || form.status == 3" :checkUsers="selUser"
          @callback="selectUsersCallback" />
        <auditshow v-if="form.status == 2 || form.status == 1" :infoData="form" taskKey="省规划办发文卡-会签意见"
          @callback="auditshowBack" />
      </td>
    </tr>
    <tr>
      <td><span style="color: red">*</span>核稿</td>
      <td colspan="2">
        <a-input-search class="select_search" readOnly v-model="form.hegaoName" placeholder="请选择核稿人" @click="onSearch"
          @search="onSearch" v-if="form.status == 0 || form.status == 3">
          <a-button type="primary" slot="enterButton" icon="plus" title="添加" />
        </a-input-search>
        <auditshow v-if="form.status == 2 || form.status == 1" :infoData="form" taskKey="省规划办发文卡-核稿"
          @callback="auditshowBack" />
      </td>
    </tr>
    <tr>
      <td style="border-bottom: none"><span style="color: red">*</span>拟稿</td>
      <td colspan="2" style="border-bottom: none; text-align: left; pointer-events: all;">
        <a-button v-if="form.status == 0 || form.status == 3" type="link" @click="showWps">{{ form.status == 0 ? "填写正文"
          : "编辑正文" }}</a-button>
        <a-button v-else type="link" @click="showWps(form.print == 1)">查看正文</a-button>
        <a-popconfirm title="提交后数据不可更改，是否确认？" @confirm="handleSubmit()" v-if="form.status == 0 || form.status == 3">
          <a-button type="link" style="margin-right: 10px">提交</a-button>
        </a-popconfirm>

        <span v-if="form.status > 0">{{ form.otherExtension.remark }}</span>
      </td>
    </tr>
    <a-modal title="选择用户" v-model="selectuservisible" width="70%" :footer="false" destroyOnClose :maskClosable="false">
      <selectuser @callback="selectUserCallback" />
    </a-modal>
  </tbody>
</template>
<script>
  import selectuser from "../components/searchuser.vue";
  import MultiSearchUser from "../components/MultiSearchUser.vue";
  export default {
    name: "guihuaban",
    props: {
      formData: {
        type: Object,
      },
    },
    data() {
      return {
        form: this.formData,
        selUser: [],
        selectuservisible: false,
      };
    },
    components: {
      selectuser,
      MultiSearchUser,
    },
    mounted() {
      if (this.formData.userInfo) {
        this.selUser = JSON.parse(this.formData.userInfo);
      }
    },
    watch: {
      formData(v) {
        this.form = v;
      },
    },
    methods: {
      auditshowBack() {
        this.$emit("callback");
      },
      handleSubmit() {
        if (
          this.form.status == 3 &&
          localStorage.getItem("publicFileId") == null
        ) {
          this.$message.error("请先重新编辑正文内容");
          return;
        }
        this.form.contentId = localStorage.getItem("publicFileId");
        if (this.form.contentId == null) {
          this.$message.error("请先填写正文内容");
          return;
        }
        // if (this.form.huiqian == null || this.form.huiqian == "") {
        //   this.$message.error("请选择会签人");
        //   return;
        // }
        if (this.form.hegao == null || this.form.hegao == "") {
          this.$message.error("请选择核稿人");
          return;
        }
        let backData = {
          extensionSettingData: {},
          userInfo: [],
        };
        let extensionSettingData = {};
        extensionSettingData["node_省规划办发文卡-核稿"] = {
          targetUsers: this.form.hegao,
          count: 1,
        };
        if (this.form.huiqian && this.form.huiqian.length > 0) {
          extensionSettingData["node_省规划办发文卡-会签意见"] = {
            targetUsers: this.form.huiqian,
            count: this.form.huiqian.split(",").length,
          };
        }
        backData.extensionSettingData = extensionSettingData;
        backData.userInfo = JSON.stringify(this.selUser);
        this.$emit("callback", backData);
      },
      onSearch() {
        this.selectuservisible = true;
      },
      selectUserCallback(user) {
        this.form.hegao = user.userName;
        this.form.hegaoName = user.realName;
        this.selectuservisible = false;
      },
      selectUsersCallback(users) {
        this.selUser = [...users];
        this.form.huiqian = users.map((u) => u.userName).join(",");
        this.form.huiqianName = users.map((u) => u.realName).join(",");
      },
      showWps() {
        let number1 = this.form.number;
        this.$emit("callback", null, true);
        if (this.form.title == "") {
          this.$message.error("请输入标题");
          return;
        }
        if (this.form.dispatchOrg == "") {
          this.$message.error("请输入主送单位");
          return;
        }
        let _number = this.form.number;
        let reg = /(.+)-(.+)/;
        let reg2 = /(.+)-(.+)-(.+)/;
        if (!reg.test(_number)) {
          this.$message.error("请正确输入发文编号");
          return;
        }
        if (!reg2.test(_number) && this.form.status == 2) {
          this.$message.error("请完善发文编号");
          return;
        }
        if (this.form.time == undefined) {
          this.$message.error("请选择发文日期");
          return;
        }
        let templateId = "fawenkaGuiHuaBan";
        let fileId = localStorage.getItem("publicFileId");
        let openType = "create";
        if (fileId != null && fileId != "") {
          openType = "edit";
          templateId = fileId;
        }
        if (this.form.contentId && this.form.contentId != "") {
          localStorage.setItem("publicFileId", this.form.contentId);
          openType = "edit";
          templateId = this.form.contentId;
        }

        if (
          this.form.status == 3 &&
          this.form.contentId == localStorage.getItem("publicFileId")
        ) {
          openType = "create";
          templateId = "fawenkaGuiHuaBan";
          localStorage.removeItem("publicFileId");
        }

        if (this.form.status != 0 && this.form.status != 3) {
          openType = "readOnly";
          localStorage.removeItem("publicFileId");
        }

        let innerData = {
          id: this.form.id,
          status: this.form.status,
          print: this.form.print,
          name: _number.split("-")[0],
          year: _number.split("-")[1],
          number: _number.split("-")[2],
          title: this.form.title,
          headTitle: this.form.dispatchOrg,
          date: this.form.time.format("YYYY年MM月DD日"),
          updateNumber: this.form.status == 2,
        };
        localStorage.setItem("innerData", JSON.stringify(innerData));
        const jump = this.$router.resolve({
          name: "wpsedit",
          query: {
            //要传的参数
            templateId: templateId,
            fileType: "public",
            openType: openType,
            fileName: "省教科院发文卡_" + _number.replaceAll("-", "_"),
            printType: "custom_fawen",
          },
        });

        window.open(jump.href, "_blank");
      },
    },
  };
</script>
